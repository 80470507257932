<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Templates <span>Change Category</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <ul class="custom_list mt-4">
                        <li v-for="(category, c) in categories" :key="c" class="selection">
                            <h5 @click="handleChangeCategory(category)">
                                <i class="fas fa-bars"></i>
                                <label class="pointer">{{ category.type }}</label>
                            </h5>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Template Change Category',

        props: {
            modelValue: Boolean,
            templates: Array,
            categories: Array,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                if (value) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        methods: {
            ...mapActions({
                changeCategory: 'templateModule/changeCategory'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleChangeCategory (category) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to move the selected template to ${ category.type }`);

                options.preConfirm = function () {
                                        return vm.changeCategory({ template_ids: vm.templates, category: category.type }).then((result) => {
                                            if (result) {
                                                vm.closeModal();

                                                if (vm.$parent.refreshTemplate) {
                                                    vm.$parent.refreshTemplate();
                                                }
                                            }
                                        });
                                    };

                Swal.fire(options);
            }
        }
    }
</script>

<style scoped>
    .custom_list {
        margin-top: 40px;
    }

    .custom_list li {
        border: 1px solid #eaeaea;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        border-radius: 6px;
        padding: 10px 15px;
        margin-bottom: 15px;
    }

    .custom_list li.selection {
        cursor: pointer;
    }

    /* .custom_list li.selection:active {
        border: 1px solid #999;
    } */

    .custom_list li h5 {
        display: flex;
        align-items: center;
        font-weight: 500;
    }

    .custom_list li h5 label {
        padding: 5px 10px;
        cursor: auto;
    }

    .custom_list li h5 span.save {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .custom_list li h5 button {
        font-size: 14px;
        margin: 5px 7px;
        cursor: pointer;
    }

    .custom_list li .section_wpr {
        border-top: 1px solid #eaeaea;
        margin: 10px -15px 0 -15px;
        padding: 10px 30px;
    }
</style>
